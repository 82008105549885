const dev = {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "notes-app-2-api-dev-attachmentsbucket-6w5zhzqfuhnt"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://duzzishcfc.execute-api.eu-west-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_fXdszai6t",
    APP_CLIENT_ID: "f3dmej7pmnrhh37pndij3dh8d",
    IDENTITY_POOL_ID: "eu-west-1:0258f45e-ada6-4b17-a40f-6fecc08aca43"
  },
  STRIPE_KEY: "sk_test_ctfke3HAlU28oo9nnsXNS7lX00vn6WUr17",
};

const prod = {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-rur38i49itk5"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://6md0hut9a1.execute-api.eu-west-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_DiZN57zpP",
    APP_CLIENT_ID: "1m9ru1ur4h0o5h8rfonqvtqm0t",
    IDENTITY_POOL_ID: "eu-west-1:75f0ca2d-6ff1-4f9d-9e92-8dc063edc188"
  },
  STRIPE_KEY: "sk_test_ctfke3HAlU28oo9nnsXNS7lX00vn6WUr17",
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
